// COMPONENTS
import React from 'react'
import Page from 'templates/Page'
// CONTENT
import content from 'content/consulting.yml'

// Consulting page
const ConsultingPage = () => {
  return <Page content={content} />
}

export default ConsultingPage
